import $ from "cash-dom"

const modal = (element) => {
  const $element = $(element)
  if (!$element.length) return

  const animate = $element.hasClass("fade") ? "fade" : ""

  const escape_key_callback = (e) => e.which === 27 && hide()

  const hide = () => {
    const $backdrop = $(".modal-backdrop")
    if (!$backdrop.length) return
    $(window).off("keyup.dismiss.modal", escape_key_callback)
    $element.trigger("hide").removeClass("in")
    setTimeout(() => {
      $backdrop.remove()
      $element.hide()
    }, animate ? 400 : 0)
    return false
  }

  $(`<div class="modal-backdrop in ${animate}" />`)
    .appendTo(document.body)
    .on("click", hide)

  $(window).on("keyup.dismiss.modal", escape_key_callback)
  $element
    .trigger("show")
    .show()
    .addClass("in")
    .find("[data-dismiss='modal']").on("click.dismiss.modal", hide)

  $element[0].focus()

  return hide
}

export default modal
