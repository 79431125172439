import $ from "cash-dom"
import rollbar_helper from "./rollbar_helper"

function load_media(noscript) {
  if (!noscript) return

  let $noscript = $(noscript)
  if (!$noscript.is("noscript")) {
    $noscript = $(noscript).find("noscript")
  }
  if (!$noscript.length) return

  // .trim() because on some browsers the content can be "\n\n"
  // also $noscript.html() can return undefined
  const html = $noscript.text().trim() || $noscript.html()?.trim()
  try {
    $noscript.replaceWith(html)
  } catch (error) {
    rollbar_helper.send_error_msg(
      "Loading media failed",
      {error, html, container: $(noscript).html()}
    )
  }
}

export default load_media
