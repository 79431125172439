import AlephBet from "alephbet"
import {
  storage_adapter,
  super_properties_lamed_adapter
} from "./adapters"
import {
  get_user_id,
  on_study_unit,
  logged_in_user,
  logged_in_potential_customer
} from "./helpers"
import {
  quiz_or_video_started,
  pricing_page_visited,
  checkout_page_visited,
  premium_subscription_started
} from "./goals"

export default () => {
  const study_unit_tour = new AlephBet.Experiment({
    name: "202502 Study unit tour",
    tracking_adapter: super_properties_lamed_adapter,
    storage_adapter: storage_adapter,
    user_id: get_user_id(),
    trigger: () =>
      on_study_unit() &&
      logged_in_potential_customer() &&
      // Thu, 20 Feb 2025 16:00:00 +0000
      logged_in_user().confirmed_at > 1740067200,
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-Tour": {
        activate: experiment => {
          if (!experiment.options.trigger()) return

          window.gon.show_study_unit_tour = true
        }
      }
    }
  })
  study_unit_tour.add_goals([
    quiz_or_video_started,
    pricing_page_visited,
    checkout_page_visited,
    premium_subscription_started
  ])
}
