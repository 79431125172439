import AlephBet from "alephbet"
import {storage_adapter, super_properties_lamed_adapter} from "./adapters"
import {logged_in_potential_customer, on_article_page, get_user_id}
  from "./helpers"
import {study_unit_visited, premium_subscription_started} from "./goals"
import $ from "cash-dom"
import load_media from "../load_media"

export default () => {
  const improve_su_discovery_from_articles = new AlephBet.Experiment({
    name: "202502 Improve study unit discovery from articles for users",
    tracking_adapter: super_properties_lamed_adapter,
    storage_adapter: storage_adapter,
    user_id: get_user_id(),
    trigger: () => logged_in_potential_customer() && on_article_page(),
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-Improved-study-units": {
        activate: () => {
          if (!on_article_page()) return

          const $firstStudyUnit = $(
            ".embedded-widget[data-media-type='study_unit']"
          ).first()
          if ($firstStudyUnit.length === 0) return

          $("body").addClass("ab-test-improved-study-units")

          if ($(".contentbox--featured").length > 0) return

          const firstStudyUnitURL = $firstStudyUnit
            .find(".contentbox")
            .attr("href")
          const $bannerImage = $firstStudyUnit.find(".contentbox-image")
          const relatedSUName = $firstStudyUnit
            .find(".contentbox-title > span")
            .text()
          const $firstSUGrid = $(".grid").first()

          const featuredSUCopy = {
            en: [
              "Step-by-step guided learning",
              "Fast mastery with spaced repetition",
              "Track progress & ace your exam",
              "Learn faster"
            ],
            es: [
              "Aprendizaje guiado paso a paso",
              "Dominio rápido con repetición espaciada",
              "Rastrea tu progreso y aprueba tu examen",
              "Aprende más rápido"
            ],
            pt: [
              "Aprendizagem guiada passo-a-passo",
              "Evolução rápida com repetição espaçada",
              "Acompanhe seu progresso e gabarite sua prova",
              "Aprenda mais rápido"
            ],
            de: [
              "Lerne Schritt-für-Schritt",
              "Effektiveres Lernen mit Spaced Repetition",
              "Fortschritt verfolgen & Prüfung bestehen",
              "Lerne schneller"
            ],
            fr: [
              "Apprentissage guidé étape par étape",
              "Maîtrise rapide avec révision efficace",
              "Suivez vos progrès & réussissez votre examen",
              "Apprenez plus vite"
            ]
          }

          const bannerCopy = {
            en: [
              "Master this topic in 30 minutes!",
              `Explore study unit: ${relatedSUName}`,
              "Explore ➞"
            ],
            es: [
              "¡Domina este tema en 30 minutos!",
              `Explora la unidad de estudio: ${relatedSUName}`,
              "Explora"
            ],
            pt: [
              "Domine esse assunto em 30 minutos!",
              `Explore a unidade de estudo: ${relatedSUName}`,
              "Explore"
            ],
            de: [
              "Meistere dieses Thema in 30 Minuten!",
              `Lerneinheit ansehen: ${relatedSUName}`,
              "Ansehen"
            ],
            fr: [
              "Maîtrisez ce sujet en 30 minutes !",
              `Explorez l’unité d’étude : ${relatedSUName}`,
              "Explorer"
            ]
          }

          $firstStudyUnit.addClass("contentbox--featured")
          const $contentboxTitle = $firstStudyUnit.find(".contentbox-title")
          const $suBanner = $(".fading-banner")
          const iconPath = window.gon.icons_path
          const $badgeElement = $firstStudyUnit.find(".contentbox-badge")
          $badgeElement.text(featuredSUCopy[window.gon.locale][3])

          if ($contentboxTitle.length > 0) {
            $contentboxTitle.after(`
              <ul class="contentbox-list">
                ${featuredSUCopy[window.gon.locale]
                  .slice(0, 3)
                  .map(text => `
                    <li>
                      <i class="circle circle--half-medium green">
                        <svg class='fa fa-check'>
                          <use href="${iconPath}#fa-check"></use>
                        </svg>
                      </i>
                      <div>${text}</div>
                    </li>
                  `)
                  .join("")}
              </ul>
            `)
          }

          if ($suBanner.length > 0) {
            const $suBannerCTA = $suBanner.find(".btn-gopremium")
            const $suBannerHeadline = $suBanner.find(".fading-banner-headline")
            const $suBannerContent = $suBanner.find(".fading-banner-headlines")
            const $clonedBannerImage = $bannerImage.clone()
            load_media($clonedBannerImage)
            $suBannerHeadline.text(bannerCopy[window.gon.locale][0])
            $suBannerCTA
              .text(bannerCopy[window.gon.locale][2])
              .attr("href", firstStudyUnitURL)
            $suBannerContent.before($clonedBannerImage)
            $suBannerContent.append(`
              <p class="fading-banner__text">
                ${bannerCopy[window.gon.locale][1]}
              </p>
            `)
          }
          if ($firstSUGrid.length > 0) {
            $firstSUGrid.before($firstStudyUnit)
          }
        }
      }
    }
  })

  improve_su_discovery_from_articles.add_goals([
    study_unit_visited,
    premium_subscription_started
  ])
}
